@import 'assets/styles/colors.scss';

.datepicker {
  .custom-input-datepicker {
    background-image: url('../../assets/images/icons/icon-calendar.svg') !important;
    padding-right: 50px !important;
    background-size: 25px;
    background-repeat: no-repeat;
    background-position: calc(100% - 10px) center;
  }
  .form-control:valid {
    background-image: none;
  }
}

.datepicker-modal {
  .cfp-content {
    width: 100% !important;
    padding: 0;
    .customDatePickerWidth .react-datepicker {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
    }
    .react-datepicker__time-container {
      width: 85px;
    }
    .react-datepicker__month-container {
      width: 100%;
    }
    .react-datepicker__navigation {
      &:focus {
        outline: none;
      }
    }
    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--selected,
    .react-datepicker__year-text--in-selecting-range,
    .react-datepicker__year-text--in-range {
      background-color: $color-app-highlight;
      &:focus {
        outline: none;
      }
    }
    .react-datepicker__time-container
      .react-datepicker__time
      .react-datepicker__time-box
      ul.react-datepicker__time-list
      li.react-datepicker__time-list-item--selected {
      background-color: $color-app-highlight;
    }
    .react-datepicker__day-names,
    .react-datepicker__week {
      display: flex;
      justify-content: space-between;
    }
    .react-datepicker__day--keyboard-selected,
    .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__quarter-text--keyboard-selected,
    .react-datepicker__year-text--keyboard-selected {
      background: transparent;
      color: #000;
      outline: none;
    }
    .react-datepicker__day--disabled,
    .react-datepicker__month-text--disabled,
    .react-datepicker__quarter-text--disabled,
    .react-datepicker__year-text--disabled {
      color: #ccc !important;
    }
  }
}
