@import "assets/styles/colors.scss";
.pro-des-container {
  color: $COLOR_TEXT;
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: 600;
    margin-bottom: 8px;
  }
  h1 {
    font-size: 22px;
  }
  h2 {
    font-size: 20px;
  }
  h3 {
    font-size: 18px;
  }
  h4 {
    font-size: 16px;
  }
  h5 {
    font-size: 14px;
  }
  img {
    border-radius: 8px;
    margin: 0 auto 16px;
    display: block;
  }
  .pro-des-title {
    font-size: 27px;
    font-weight: 600;
    margin-bottom: 16px;
    color: $color-app-highlight;
  }
}
