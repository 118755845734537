@import "assets/styles/colors.scss";
.dl-col-custom {
  margin: 0 15px;
}
.use-owner-info {
  color: $AEON_GRADIENT;
}
.get-use-owner-info {
  color: $AEON_GRADIENT;
  padding: 8px;
  margin-bottom: 8px;
  background-color: $BTN_IC_BG_GRAY;
  border-radius: 6px;
  border: 1px solid $AEON_GRADIENT;
}
