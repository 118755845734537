@import 'assets/styles/colors.scss';

.box-gift {
  padding-top: 120px;
  .img-gift {
    margin-bottom: 20px;
  }
  .title {
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
  }
  .des {
    font-weight: normal;
    font-size: 16px;
    text-align: center;
    color: $color-sub-title;
    margin-bottom: 24px;
  }
  .btn-answer {
    border-radius: 28px;
    padding: 14px 24px;
    font-weight: 500;
    text-transform: uppercase;
    background: $color-app-highlight;
    border: transparent;
    margin-bottom: 48px;
    box-shadow: 0px 17px 14px rgba(0, 0, 0, 0.08), 0px 11.385px 8.81346px rgba(0, 0, 0, 0.0513216),
      0px 8.88951px 6.45393px rgba(0, 0, 0, 0.0391894),
      0px 7.29826px 5.36153px rgba(0, 0, 0, 0.0330892),
      0px 4.87517px 3.59443px rgba(0, 0, 0, 0.0243451);
    img {
      padding-right: 25px;
      margin-right: 25px;
      display: inline-block;
      border-right: 1px solid #fff;
    }
  }
  .des-sale {
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    text-transform: uppercase;
    color: $COLOR_TEXT;
  }
}
