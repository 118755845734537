@import 'assets/styles/colors.scss';

.plan-item {
  width: 100%;
  text-align: center;
  position: relative;
  &.active {
    background: $color-app-highlight;
    .title-box {
      color: white;
    }
    .title-premium {
      color: white;
    }
    .custom[type='radio']:checked:after {
      background: $color-app-highlight;
    }
    .custom[type='radio']:checked {
      background: white;
    }
  }
  .title-plan {
    width: 100%;
    margin: 20px 0px;
  }
  .custom[type='radio'] {
    vertical-align: middle;
    padding: 9px;
    width: 10px;
    line-height: 2px;
    text-indent: -12px;
    height: 10px;
    display: inline-block;
    background: white;
    border-radius: 50%;
    border: 1px solid rgba(0, 0, 0, 0.2);
    position: relative;
    margin-top: 12px;
  }
  .custom[type='radio']:checked {
    background: $color-app-highlight;
  }
  .custom[type='radio']:checked {
    background: $color-app-highlight;
  }

  .custom[type='radio']:checked:after {
    background: white;
    content: '';
    width: 8px;
    height: 8px;
    position: absolute;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .custom {
    appearance: none;
    box-sizing: border-box;
    width: 100%;
    // padding: 15px;
    border: 2px solid #fff;
    font-size: 24px;
  }
  .custom:focus {
    border-color: $color-app-highlight;
    color: $color-app-highlight;
    background: transparent;
    outline: none;
  }

  /** Select item*/
  .pi-pi-content-outer {
    width: 100%;
    color: $COLOR_TEXT;
    h3 {
      margin: 0px;
      font-size: 16px;
      font-weight: 500;
      text-transform: uppercase;
    }
    ul {
      list-style: none;
    }
    ul li::before {
      content: '\2022';
      color: $color-app-highlight;
      font-weight: bold;
      display: inline-block;
      width: 12px;
      margin-left: -1em;
    }
    img {
      margin: 0 auto !important;
    }
  }
  .pi-header-box {
    cursor: pointer;
    position: relative;
    .pi-header-title {
      font-weight: 600;
      font-size: 16px;
      text-transform: uppercase;
    }
  }

  .title-box {
    font-weight: 600;
    font-size: 12px;
    color: #b41f8e;
    margin: 0px;
    text-transform: uppercase;
  }

  .title-premium {
    font-size: 17px;
    line-height: 20px;
    text-transform: uppercase;
    color: #263238;
    margin-top: 8px;
    font-weight: 600;
    span {
      font-weight: normal;
      font-size: 13px;
    }
  }
}
