@import 'assets/styles/colors.scss';
.common-questionnaire-wrapper {
  .questionnaire-form {
    margin-bottom: 160px;
    .section-wrapper {
      padding-top: 16px;
      .section-header {
        padding: 0 0 11px 0;
        border-bottom: 1px solid $COLOR_BORDER;
        .category-title {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          text-transform: uppercase;
          color: $color-title;
          border-bottom: 1px solid $color-app-highlight;
          padding-bottom: 15px;
        }
      }
      .section-content {
        padding-top: 0px;
        .question-content {
          font-weight: 600;
          font-size: 15px;
          line-height: 24px;
          color: $TXT_BOLD_COLOR;
          .question-text {
            font-size: 20px;
            font-weight: 500;
          }
        }
        .question-content.warning {
          padding-right: 24px;
          background-image: url('../../assets/images/icons/icon-warning.svg') !important;
          background-position: top 50% right 0px !important;
          background-size: 24px !important;
          background-repeat: no-repeat !important;
        }
        .error-content {
          padding-top: 4px;
          font-style: italic;
          font-size: 13px;
          line-height: 20px;
          color: $ERROR_MESSAGE;
        }
        .option-wrapper {
          padding-top: 24px;
          text-align: start;
          .header {
            margin: 0;
            font-weight: 500;
            font-size: 12px;
            line-height: 20px;
            text-transform: uppercase;
            color: $color-title;
            padding-bottom: 16px;
            border-bottom: 1px solid $COLOR_BORDER;
            .col {
              padding-left: 0;
            }
            & :not(:first-child) {
              max-width: 72px;
              text-align: center;
            }
          }
          .body {
            margin: 0;
            font-weight: 500;
            font-size: 13px;
            line-height: 20px;
            color: $color-app-highlight;
            padding: 18px 0;
            border-bottom: 1px solid $COLOR_BORDER;
            .col {
              padding-left: 0;
              [type='radio']:checked + label:before,
              [type='radio']:not(:checked) + label:before {
                left: 6px;
                top: -16px;
              }
              [type='radio']:checked + label:after,
              [type='radio']:not(:checked) + label:after {
                top: -10px;
                left: 12px;
              }
            }
            & :not(:first-child) {
              max-width: 72px;
              text-align: center;
            }
          }
          & :last-child {
            border: 0;
          }
        }
      }
      .note-text {
        span {
          font-weight: 600;
          font-size: 14px;
          line-height: 24px;
          text-transform: none;
          margin-left: 5px;
          font-style: italic;
        }
      }
    }
    .section-confirm {
      position: fixed;
      bottom: 76px;
      left: 0;
      width: 100%;
      z-index: 2;
      background-color: #fff;
      .confirm-content {
        padding: 16px 0 8px 0;
        margin: 0 20px;
        border-top: 1px solid $COLOR_BORDER;
      }
      span {
        color: $color-app-highlight;
      }
      .note-content {
        text-decoration: underline;
        margin: 0 20px;
        font-style: italic;
      }
    }
    .progress-wrapper {
      .header {
        margin-bottom: 8px;
        .title {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          text-transform: uppercase;
          color: $TXT_BOLD_COLOR;
        }
        .number {
          color: $color-app-highlight;
          font-weight: 500;
        }
      }
    }
    .progress {
      height: 3px;
      margin-bottom: 8px;
      .progress-bar {
        background: $color-app-highlight;
      }
    }
    .notice-text {
      font-size: 11px;
      font-style: italic;
      font-weight: 500;
    }
  }
  .html-parser-wrapper {
    color: $COLOR_TEXT;
    overflow: auto;
    width: 100%;
    h1,
    h2,
    h3,
    h4,
    h5 {
      font-weight: 600;
      margin-bottom: 8px;
    }
    h1 {
      font-size: 22px;
    }
    h2 {
      font-size: 20px;
    }
    h3 {
      font-size: 18px;
    }
    h4 {
      font-size: 16px;
    }
    h5 {
      font-size: 14px;
    }
    img {
      border-radius: 8px;
      margin: 0 auto 16px;
      display: block;
    }
    .pro-des-title {
      font-size: 27px;
      font-weight: 600;
      margin-bottom: 16px;
      color: $color-app-highlight;
    }
  }
  .all {
    color: $COLOR_TEXT;
  }
}
