.actions-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  .-blur {
    height: 32px;
    background-image: none;
    background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 90%); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      top,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 90%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 90%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
  }
  .-action-container {
    background-color: white;
    padding: 16px;
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    z-index: 10;
    margin-left: 2px;
    button:nth-child(2) {
      margin-left: 16px;
    }
    .-action-component {
      background-color: white;
      height: fit-content;
      width: 100%;
    }
  }
}
