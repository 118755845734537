$base-width: 32px;
.header {
  width: 100%;
  margin: 0 auto;
  .nav {
    padding: 30px 0px;
    align-items: center;
    margin: 0;
    flex-wrap: nowrap;
    position: relative;
    .box {
      z-index: 9999;
      width: 100%;
    }
  }
  &.full-width {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
  }
  &.active {
    top: 0;
    z-index: 99;
    background: white;
    position: fixed;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  }
}

.left-header,
.right-header {
  width: $base-width;
  min-width: $base-width;
  z-index: 100;
  flex: initial;
}

.title-header {
  position: absolute;
  width: calc(100% - 84px);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  overflow: hidden;
  font-size: 18px;
  line-height: 24px;
  text-transform: uppercase;

  .icon-aeon {
    left: 19.2%;
    right: 56.8%;
    top: 3.32%;
    bottom: 95.23%;
  }
}
