@import 'assets/styles/colors.scss';

.optional-item {
  position: relative;
  background-size: 35px auto;
  background-repeat: no-repeat;
  .box-checkbox {
    position: absolute;
    top: 5px;
    left: 0;
  }
  .box {
    line-height: 22px;
    .price {
      font-weight: 500;
      font-size: 17px;
      font-weight: 500;
      margin-top: 12px;
      padding-bottom: 12px;
      &.active {
        color: $color-app-highlight;
      }
      span {
        font-weight: normal;
        font-size: 12px;
      }
    }
  }
  .title-object {
    color: $COLOR_TEXT;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 8px;
    margin-top: 12px;
  }
  .box-optional {
    margin-top: 8px;
    margin-bottom: 15px;
  }
  .box-title {
    border-top: 1px solid $COLOR_BORDER;
    .title-optional {
      margin-right: 10px;
      width: calc(100% - 78px);
      font-style: normal;
      line-height: 24px;
      padding: 16px 0px;
      color: $COLOR_TEXT;
      font-weight: 500;
      font-size: 15px;
    }
  }
  .title-optionals {
    font-weight: 500;
    color: $COLOR_TEXT;
    font-size: 16px;
    text-transform: uppercase;
    padding-top: 5px;
    padding-bottom: 12px;
  }
}
