@import 'assets/styles/colors.scss';

.select-plans {
  .benefit,
  .effective-date {
    width: 100%;
    margin-top: 25px;
    .form-label {
      color: $COLOR_TEXT;
      font-weight: 500;
      font-size: 16px;
      margin-bottom: 10px;
    }
    .form-input-wrapper.form-group {
      margin-bottom: 5px !important;
    }
    .message-error {
      font-size: 13px;
      color: red;
    }
    .pf-date-small.border-error {
      border-color: red;
      &:focus {
        box-shadow: none;
      }
    }
  }
  .box-plans {
    display: flex;
    border-top: 2px solid $COLOR_BORDER;
    border-bottom: 1px solid $COLOR_BORDER;
  }
}

.optionals {
  margin-top: 20px;
}
