@import "assets/styles/colors.scss";

.cfp-title {
  text-transform: uppercase;
  font-weight: normal !important;
}

.cfp-content {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  padding-top: 0px;
  text-align: center;
  white-space: pre-wrap;
  p {
    margin: 0;
  }
  .hightlight-text {
    color: $BV_GRADIENT;
    font-weight: 500;
  }
  .font-lage {
    font-size: 22px !important;
  }
}

.cfp-wrapper .modal-content {
  margin: 0 10px;
  border: none !important;
  .modal-header {
    border-bottom: none !important;
  }
  .modal-footer {
    border-top: none !important;
  }
}
