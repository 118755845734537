@import "assets/styles/colors.scss";
.form-input-wrapper.form-group {
  margin-bottom: 20px !important;
  .form-label {
    font-size: 14px;
    line-height: 20px;
    color: $COLOR_TEXT_3;
    margin-bottom: 4px;
  }
  .form-control {
    border: 1px solid $COLOR_BORDER;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 12px;
    font-size: 15px;
    line-height: 24px;
    color: $COLOR_TEXT_2;
    height: 48px;
  }

  .is-invalid {
    border-color: $COLOR_RED;
  }

  textarea {
    height: auto !important;
  }

  /**
    Hide Arrows From Input Number
  */

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox hide*/
  input[type="number"] {
    -moz-appearance: textfield;
  }

  /**
    Icon calender
  */

  input[type="date"],
  input[type="datetime-local"] {
    background: url("../../assets/images/icons/icon-calendar.svg") !important;
    background-position: bottom 12px right 12px !important;
    background-size: 24px !important;
    background-repeat: no-repeat !important;
  }

  input[type="date"]::-webkit-inner-spin-button,
  input[type="date"]::-webkit-outer-spin-button,
  input[type="datetime-local"]::-webkit-inner-spin-button,
  input[type="datetime-local"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="date"]::-webkit-inner-spin-button,
  input[type="datetime-local"]::-webkit-inner-spin-button {
    display: none;
  }

  input[type="date"]::-webkit-clear-button,
  input[type="datetime-local"]::-webkit-clear-button {
    position: absolute;
    right: 40px;
    transform: scale(1.25);
    display: none;
  }

  input[type="date"]::-webkit-calendar-picker-indicator,
  input[type="datetime-local"]::-webkit-calendar-picker-indicator {
    color: rgba(0, 0, 0, 0);
    width: 8px;
    height: 10px;
    position: absolute;
    right: 15px;
  }

  input[type="date"]::-webkit-calendar-picker-indicator:hover,
  input[type="datetime-local"]::-webkit-calendar-picker-indicator:hover {
    background: transparent;
    cursor: pointer;
  }
}
