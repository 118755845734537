@import 'assets/styles/colors.scss';

.item-product {
  min-height: 170px;
  margin-left: 20px;
  background: #ecf3f8;
  border-radius: 16px 16px 60px 16px;
  margin-bottom: 20px;
  padding: 24px 16px 32px 96px;
  position: relative;
  .icon-product {
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    top: 24px;
    left: -20px;
    width: 100px;
    height: 100px;
    overflow: hidden;
    box-shadow: 0px 17px 14px rgba(0, 0, 0, 0.08), 0px 11.385px 8.81346px rgba(0, 0, 0, 0.0513216),
      0px 8.88951px 6.45393px rgba(0, 0, 0, 0.0391894),
      0px 7.29826px 5.36153px rgba(0, 0, 0, 0.0330892),
      0px 4.87517px 3.59443px rgba(0, 0, 0, 0.0243451);
    border-radius: 16px;
    .sold,
    .bought {
      border-radius: 4px 16px;
      padding: 4px 12px;
      color: white;
      font-size: 13px;
      position: absolute;
      bottom: 0;
      left: 0;
    }
    .sold {
      background: #ce7fba;
    }
    .bought {
      border-radius: 4px 16px;
      background: #0077e1;
      padding: 4px 12px;
      color: white;
    }
  }
  .title {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 8px;
    max-height: 56px;
    overflow: hidden;
    color: $COLOR_TEXT;
  }
  .des {
    color: $COLOR_TEXT;
    opacity: 0.85;
    line-height: 20px;
    max-height: 60px;
    overflow: hidden;
  }
  .link-now {
    width: 40px;
    height: 40px;
    background: $color-app-highlight;
    border-radius: 50%;
    position: absolute;
    right: 0px;
    background-image: url('../../assets/images/icons/icon-arrow-right-long.svg');
    background-repeat: no-repeat;
    background-position: center;
    a {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  .plan {
    font-style: normal;
    font-size: 16px;
    color: #0077e1;
    padding-left: 25px;
    position: relative;
    margin-bottom: 4px;
    display: flex;
    span {
      display: inline-block;
      padding-left: 5px;
    }
    &:before {
      content: '';
      width: 20px;
      height: 20px;
      display: inline-block;
      position: absolute;
      left: 0;
      top: 0;
      background-image: url('../../assets/images/icons/icon-check-plan.svg');
      background-size: 100%;
    }
  }
}
