@import "assets/styles/colors.scss";
@font-face {
  font-family: "icon";
  src: url("../../../assets/fonts/icon/icomoon.eot?sva1co");
  src: url("../../../assets/fonts/icon/icomoon.eot?sva1co#iefix") format("embedded-opentype"),
    url("../../../assets/fonts/icon/icomoon.ttf?sva1co") format("truetype"),
    url("../../../assets/fonts/icon/icomoon.woff?sva1co") format("woff"),
    url("../../../assets/fonts/icon/icomoon.svg?sva1co#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.menu-sidebar {
  position: relative;
  margin-left: 12px;
  .item-menu {
    align-items: center;
    margin-bottom: 15px;
    .link {
      height: 40px;
      position: relative;
      padding-left: 55px;
      color: #41606b;
      display: flex;
      align-items: center;
      &.active {
        color: $color-app-highlight;
        > span:before {
          color: $color-app-highlight;
        }
      }
    }
    span[class^="icon-icon"] {
      width: 40px;
      height: 40px;
      background: #ecf3f8;
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 0;
      &:before {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-family: "icon";
        color: #607d8b;
        font-size: 20px;
        padding: 8px;
        display: inline-block;
      }
    }
  }
  .logout {
    .logout-icon {
      width: 40px;
      height: 40px;
      background: #ecf3f8;
      border-radius: 50%;
      transform: rotate(90deg);
      padding: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 18px;
        height: 18px;
      }
    }
    .label {
      height: 40px;
      position: relative;
      padding-left: 15px;
      color: #41606b;
      display: flex;
      align-items: center;
    }
  }
}

.icon-icon-product:before {
  content: "\e900";
}
.icon-icon-home:before {
  content: "\e903";
}
.icon-icon-indemnify:before {
  content: "\e904";
}
.icon-icon-news:before {
  content: "\e905";
}
.icon-icon-promotion:before {
  content: "\e907";
}
.icon-icon-qes-ans:before {
  content: "\e908";
}
.icon-icon-rules:before {
  content: "\e909";
}
.icon-icon-contract::before {
  content: "\e905";
}
.box-footer {
  padding: 10px 15px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  .version {
    text-align: right;
    padding-bottom: 10px;
    margin-bottom: 10px;
    color: #212529b3;
    border-bottom: 1px solid $COLOR_BORDER;
  }
  .icon-footer {
    text-align: center;
    img {
      width: 120px;
    }
  }
}

.lng-tab {
  position: absolute !important;
  right: 0px;
  top: -20px;
  align-items: center !important;
  display: flex !important;
  flex-direction: row !important;
  height: 10px !important;
  border: none;
  padding: 0px !important;
  button {
    line-height: 1px;
    background-color: #dee2e640;
    margin: 1px;
    padding: 10px 10px;
    height: 10px;
    border-radius: 4px !important;
  }

  .active {
    button {
      border-radius: 4px !important;
      background-color: #74b9ff;
      color: white;
    }
  }
}
