.news-item-vertical {
  margin-bottom: 25px;
  .thumbnail {
    float: left;
    width: 40%;
    display: block;
    padding-top: 21%;
    position: relative;
    margin-right: 12px;
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  .content-box {
    float: left;
    width: calc(100% - 12px);
  }
  .title {
    font-size: 15px;
    color: #022b39;
    line-height: 24px;
    font-weight: 500;
  }
  .tag {
    margin-top: 8px;
    position: relative;
    padding-left: 24px;
    font-size: 12px;
    color: #b41f8e;
    text-transform: uppercase;
    &:before {
      position: absolute;
      content: '';
      width: 20px;
      height: 20px;
      background-image: url('../../assets/images/icons/icon-tag.svg');
      background-size: 100%;
      background-repeat: no-repeat;
      left: 0;
      top: 0;
    }
  }
}
