.buffer-line {
  background-color: #e6eaeb;
  width: 100%;
  height: 1px;
}

.pf-title {
  color: #607d8b;
  max-width: 101px;
  padding: 0;
}

.pf-item {
  padding: 14px 0;
  font-size: 14px;
}

.pf-id {
  font-size: 16px;
  font-weight: 500;
}

.footer-button {
  bottom: 16px;
  position: absolute;
  width: calc(100% - 40px);
}

.pf-info {
  color: #022b39;
}
