.drawer {
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  width: 80%;
  max-width: 350px;
  z-index: 1000;
  transform: translateX(-100%);
  transition: transform 0.3s ease-out;
  background: white;
  padding: 20px;
  overflow-y: scroll;
}

.drawer.open {
  transform: translateX(0);
}

.drawer ul {
  height: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (min-width: 769px) {
    .drawer {
        display: none;
    }
}
