.tm-app-loader {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: #ffffffd9;
  .loader {
    position: absolute;
    top: calc(50% - 16px);
    left: calc(50% - 16px);
    width: 32px;
    height: 32px;
    background-image: url('../../assets/images/icons/icon-loading.svg');
    animation: load 1.5s infinite linear;
    transition: opacity 1s;
    z-index: 10000;
    background-repeat: no-repeat;
  }

  @keyframes load {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
