$COLOR_AEON_1: #b41f8e;
$COLOR_AEON_2: #ff6561;
$COLOR_AEON_3: #e63778;
$COLOR_BV_1: #0096a9;
$COLOR_BV_2: #bbbe4e;

$COLOR_TEXT: #263238;
$COLOR_TEXT_2: #263238;
$COLOR_TEXT_3: #607d8b;
$COLOR_BG: #eceff1;
$COLOR_BORDER: #e6eaeb;

$COLOR_INFO: #e1f5fe;
$COLOR_SUCCESS: #e8f5e9;
$COLOR_ERROR: #ffebee;
$COLOR_WARNING: #fff3e0;

$COLOR_RED: #ff6561;
$COLOR_BLUE: #0077e1;
$COLOR_VIOLET: #ce7fba;

$AEON_GRADIENT: #b41f8e;
$BV_GRADIENT: #b41f8e;

$BORDER_RADIUS_SMALL: 4px;
$BORDER_RADIUS_MEDIUM: 8px;
$BORDER_WIDTH_SMALL: 2px;

$app-button: #b41f8e;
$padding-top-page-fullwidth: 95px;
$font-size-app: 16px;
$line-height-app: 24px;
$color-app-highlight: #b41f8e;
$background-card: #b41f8e;
$color-title: #263238;
$color-sub-title: #455a64;

$BTN_BG_GRAY: #ecf3f8;
$BTN_IC_BG_GRAY: #f9fbfc;
$TXT_BOLD_COLOR: #455a64;
$CARD_BG_COLOR_GRAY: #ecf3f8;
$ERROR_MESSAGE: #f44336;

$primary-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
