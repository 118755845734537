@import 'assets/styles/colors.scss';
.drawer-header {
  .ico-avt {
    width: 80px;
    height: 80px;
    overflow: hidden;
    border-radius: 50%;
    background: $color-app-highlight;
    margin-bottom: 16px;
    img {
      transform: translate(50%, 50%);
    }
  }
  .ico-setting a {
    width: 24px;
    height: 24px;
    overflow: hidden;
  }
  .user-info {
    .name {
      color: $COLOR_TEXT;
      font-size: 18px;
      text-transform: uppercase;
    }
    .age {
      color: #607d8b;
      font-size: 13px;
      margin-bottom: 24px;
    }
  }
  .border-bottom {
    border-bottom: 1px solid #e6eaeb;
    margin-bottom: 30px;
  }
}
