@import "assets/styles/colors.scss";
@import "assets/styles/classes.scss";
.login-container {
  padding-top: 24px;
  color: $COLOR_TEXT;
  h2 {
    font-size: 20px;
    margin-bottom: 16px;
  }
  h3,
  p {
    font-size: 13px;
  }
}
