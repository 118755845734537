@import 'assets/styles/colors.scss';

@font-face {
  font-family: 'Asap';
  font-weight: normal;
  font-style: normal;
  src: local('Asap'), url(./assets/fonts/asap/Asap-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Asap';
  font-weight: 500;
  font-style: normal;
  src: local('Asap'), url(./assets/fonts/asap/Asap-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Asap';
  font-weight: 600;
  font-style: normal;
  src: local('Asap'), url(./assets/fonts/asap/Asap-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Asap';
  font-weight: bold;
  font-style: normal;
  src: local('Asap'), url(./assets/fonts/asap/Asap-SemiBold.ttf) format('truetype');
}

body {
  font-size: $font-size-app !important;
  line-height: $line-height-app !important;
  font-family: Asap !important;
  word-break: break-word;
}
.App {
  text-align: center;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-link {
  color: #61dafb;
}
* {
  box-sizing: border-box;
}
a:hover {
  text-decoration: none;
}
.row-custom {
  margin-left: -8px;
  margin-right: -8px;
}
.col-custom {
  padding: 0px 8px;
}
img {
  max-width: 100%;
  height: auto;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.custom-form-checkbox-input {
  padding-left: 0px;
  input {
    position: absolute;
    opacity: 0;
    display: none;
    & + label {
      position: relative;
      cursor: pointer;
      padding: 0;
    }
    & + label:before {
      content: '';
      margin-right: 10px;
      display: inline-block;
      vertical-align: text-top;
      width: 20px;
      height: 20px;
      background: $COLOR_BG;
      border: solid 1px rgba(0, 0, 0, 0.1);
      border-radius: $BORDER_RADIUS_SMALL;
    }
    &:hover + label:before {
      // background: $COLOR_BORDER;
    }
    &:focus + label:before {
      box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.1);
    }
    &:checked + label:before {
      // background: $COLOR_AEON_3;
      border: solid 1px $COLOR_BORDER;
    }
    &:disabled + label {
      color: #b8b8b8;
      cursor: auto;
    }
    &:disabled + label:before {
      box-shadow: none;
      background: #ddd;
    }
    &:checked + label:after {
      content: '';
      position: absolute;
      left: 5px;
      top: 10px;
      background: $COLOR_AEON_3;
      width: 2px;
      height: 2px;
      box-shadow: 2px 0 0 $COLOR_AEON_3, 4px 0 0 $COLOR_AEON_3, 4px -2px 0 $COLOR_AEON_3,
        4px -4px 0 $COLOR_AEON_3, 4px -6px 0 $COLOR_AEON_3, 4px -8px 0 $COLOR_AEON_3;
      transform: rotate(45deg);
    }
  }
}

.common-checkbox {
  padding-left: 0px;
  input {
    position: absolute;
    opacity: 0;
    display: none;
    & + label {
      position: relative;
      cursor: pointer;
      padding: 0;
    }
    & + label:before {
      content: '';
      margin-right: 10px;
      display: inline-block;
      vertical-align: text-top;
      width: 20px;
      height: 20px;
      background: $COLOR_BG;
      border: solid 1px rgba(0, 0, 0, 0.1);
      border-radius: $BORDER_RADIUS_SMALL;
    }
    &:hover + label:before {
      // background: $COLOR_BORDER;
    }
    &:focus + label:before {
      box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.1);
    }
    &:checked + label:before {
      // background: $COLOR_AEON_3;
      border: solid 1px $COLOR_BORDER;
    }
    &:disabled + label {
      color: #b8b8b8;
      cursor: auto;
    }
    &:disabled + label:before {
      box-shadow: none;
      background: #ddd;
    }
    &:checked + label:after {
      content: '';
      position: absolute;
      left: 5px;
      top: 10px;
      background: $COLOR_AEON_3;
      width: 2px;
      height: 2px;
      box-shadow: 2px 0 0 $COLOR_AEON_3, 4px 0 0 $COLOR_AEON_3, 4px -2px 0 $COLOR_AEON_3,
        4px -4px 0 $COLOR_AEON_3, 4px -6px 0 $COLOR_AEON_3, 4px -8px 0 $COLOR_AEON_3;
      transform: rotate(45deg);
    }
  }
}

.tm-popup-footer {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  z-index: 10;
  button:nth-child(2) {
    margin-left: 16px;
  }
}

.custom-form-radio-input {
  padding-left: 0 !important;
  padding-right: 40px;
  input {
    position: absolute;
    opacity: 0;
    display: none;
    & + label {
      position: relative;
      cursor: pointer;
      padding: 0;
    }
    & + label:before {
      content: '';
      margin-right: 10px;
      display: inline-block;
      vertical-align: text-top;
      width: 20px;
      height: 20px;
      background: $COLOR_BG;
      border: solid 1px $COLOR_BORDER;
      border-radius: 30px;
    }
    &:hover + label:before {
      background: rgba(180, 31, 142, 0.2);
    }
    &:focus + label:before {
      box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.1);
    }
    &:checked + label:before {
      // background: $COLOR_BG;
    }
    &:disabled + label {
      color: #b8b8b8;
      cursor: auto;
    }
    &:disabled + label:before {
      box-shadow: none;
      background: #ddd;
    }
    &:checked + label:after {
      content: '';
      position: absolute;
      top: 6.3px;
      left: 5px;
      background: $COLOR_AEON_3;
      border-radius: 20px;
      width: 10px;
      height: 10px;
    }
  }
}

.form-control.is-invalid {
  background-image: none !important;
  border-color: #ff6561 !important;
}
