.s-column-custom {
  margin: 0 15px;
}

.note {
  text-decoration: underline;
  font-weight: bold;
  font-style: italic;
  float: left;
}
