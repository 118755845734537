@import "assets/styles/colors.scss";
.tg-content-outer {
  background-color: $BTN_IC_BG_GRAY;
  padding: 14px 12px;
  margin-top: 24px;
  // margin-bottom: 8px;
  color: $COLOR_TEXT;
  h3 {
    margin: 0px;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
  }
  ul {
    list-style: none;
  }
  ul li::before {
    content: "\2022";
    color: $color-app-highlight;
    font-weight: bold;
    display: inline-block;
    width: 12px;
    margin-left: -1em;
  }
  img {
    margin: 0 auto !important;
  }
}
.tg-header-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  .icon-arrow-down {
    margin: 0 !important;
    margin-left: 16px !important;
    transition: all 0.3s !important;
    transform: translateY(-50%) rotate(0deg);
    &.active {
      transform: translateY(-50%) rotate(180deg);
    }
  }
  .tg-header-title {
    font-weight: 600;
    font-size: 16px;
    text-transform: uppercase;
  }
}

.tg-content-box {
  height: 0;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s;
  padding: 0px;
  position: relative;
  overflow: hidden;
  &.active {
    height: auto;
    visibility: visible;
    opacity: 1;
    padding-top: 10px;
    overflow: visible;
  }
}
