@import 'assets/styles/colors.scss';
.title-box {
  position: relative;
  margin-top: 44px;
  margin-bottom: 16px;
  font-style: normal;
  font-weight: 500;
  h2 {
    color: #022b39;
    text-transform: uppercase;
    font-size: 16px;
  }
  .redirect-all {
    position: absolute;
    right: 10px;
    top: 0px;
    font-size: 13px;
    padding-right: 10px;
    a {
      color: $color-app-highlight;
    }
    &::before {
      position: absolute;
      top: 2px;
      right: -11px;
      content: '';
      width: 20px;
      height: 20px;
      background-image: url('../../../../assets/images/icons/icon-arrow-right.svg');
      background-size: 100%;
    }
  }
}
