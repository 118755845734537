@import "assets/styles/colors.scss";
.delivery-method {
  .delivery-radio-custom[type="radio"] {
    vertical-align: middle;
    padding: 9px;
    width: 10px;
    line-height: 2px;
    text-indent: -12px;
    height: 10px;
    display: inline-block;
    background: white;
    border-radius: 50%;
    margin-right: 8px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    position: relative;
  }
  .delivery-radio-custom[type="radio"]:checked {
    background: $color-app-highlight;
  }

  .delivery-radio-custom[type="radio"]:checked:after {
    background: white;
    content: "";
    width: 8px;
    height: 8px;
    position: absolute;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .delivery-radio-custom {
    appearance: none;
    box-sizing: border-box;
    width: 100%;
    border: 2px solid #fff;
    font-size: 24px;
  }
  .delivery-radio-custom:focus {
    border-color: $color-app-highlight;
    color: $color-app-highlight;
    background: transparent;
    outline: none;
  }

  .rd-select-method {    
    margin: 0px 6px;
    width: calc(100% - 4px);
    height: 68px;
    background: $BTN_BG_GRAY;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 16px 22px;
    line-height: 35px;
    white-space: pre;
    font-size: 13px;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }

  .border-box {
    position: relative;
    top: -52px;
    left: -22px;
    width: calc(100% + 44px);
    height: calc(100% + 34px);
    border: 1px solid $COLOR_BORDER;
    z-index: -1;
    border-radius: 8px;
  }
  .delivery-radio-custom[type="radio"]:checked ~ .border-box {
    border: 1px solid $color-app-highlight;
    z-index: 1;
  }
  display: flex;
  .rd-select-outer {
    flex: 1;
  }
}
