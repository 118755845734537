@import 'assets/styles/colors.scss';
.form-input-wrapper.form-group {
  margin-bottom: 20px !important;
  .form-label {
    font-size: 14px;
    line-height: 20px;
    color: $COLOR_TEXT_3;
    margin-bottom: 4px;
  }
  .form-control {
    border: 1px solid $COLOR_BORDER;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 12px;
    font-size: 15px;
    line-height: 24px;
    color: $COLOR_TEXT_2;
    height: 48px;
  }
  .is-invalid {
    border-color: #dc3545;
  }
  select,
  select.form-control:valid {
    background-image: url('../../assets/images/icons/icon-arrow-down.svg') !important;
    background-position: bottom 18px right 18px !important;
    background-size: 12px !important;
    padding: 12px 40px 12px 12px !important;
    background-repeat: no-repeat !important;
    -webkit-appearance: none;
    -moz-appearance: none; /* Remove default arrow */
  }
}
