.iis-wrapper {
  .tm-question-mark {
    position: absolute;
    right: 15px;
    top: 0px;
  }
  .ii-radio-group {
    .radio:nth-child(2) {
      margin-left: 40px;
    }
  }
}
