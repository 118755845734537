@import 'assets/styles/colors.scss';
.ii-wrapper {
  .pf-title {
    color: #607d8b;
    max-width: 101px;
    padding: 0;
    font-size: 14px;
  }

  .pf-item {
    padding: 12px 1px 20px 3px;
  }

  .pf-header {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    color: #022b39;
  }

  .pf-date-small {
    font-size: 0.8rem;
  }

  .form-group {
    margin-bottom: 20px !important;
  }

  col-custom .form-control:invalid {
    background-image: none !important;
  }

  .form-control:valid {
    background-image: none !important;
  }

  .form-check-label {
    color: black !important;
  }

  .ii-col-custom {
    margin: 0 15px;
  }

  .ii-radio-group {
    .radio:nth-child(2) {
      margin-left: 40px;
    }
  }

  .ii-wrapper {
    .form-control .is-invalid {
      background-image: none;
    }
  }

  .label-contact {
    margin-top: 12px;
  }
  .pf-item-1 {
    margin-bottom: 20px;
  }
}
