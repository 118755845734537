@import 'assets/styles/colors.scss';
.ili-item-wrapper {
  border-top: thin solid;
  border-width: 1px;
  border-color: $COLOR_BORDER;
  padding: 16px 0;
  justify-content: space-between;
  .user-info {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    margin: 0 !important;
  }
  .action {
    display: flex;
    .icon {
      display: inline-block;
      width: 40px;
      height: 40px;
      background: $BTN_IC_BG_GRAY;
      margin-left: 12px;
      border-radius: 50%;
      position: relative;
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
