@import '../../assets/styles/colors.scss';
@import '../../assets/styles/classes.scss';
.btn-base {
  height: 44px;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: white;
  border-radius: $BORDER_RADIUS_SMALL;
  padding: 8px 24px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-touch-callout: none !important;
  -ms-user-select: none !important;
  -webkit-user-select: none !important;
  -moz-user-select: -moz-none !important;
  text-transform: uppercase;
  white-space: nowrap;
}

.btn-base-primary {
  cursor: pointer;
  background: $AEON_GRADIENT;
  font-weight: 500;
  @include box-shadow($primary-shadow);
}

.disabled-primary {
  opacity: 0.5;
  cursor: default;
}

.btn-base-outline-primary {
  color: $color-app-highlight;
  background: white;
  position: relative;
  border-radius: calc(#{$BORDER_RADIUS_SMALL} - 2px);
  height: calc(100% - #{$BORDER_WIDTH_SMALL * 2});
  width: calc(100% - #{$BORDER_WIDTH_SMALL * 2});
  font-weight: 500;
  &:after {
    content: '';
    position: absolute;
    top: -$BORDER_WIDTH_SMALL;
    left: -$BORDER_WIDTH_SMALL;
    height: calc(100% + #{$BORDER_WIDTH_SMALL * 2});
    width: calc(100% + #{$BORDER_WIDTH_SMALL * 2});
    background: $AEON_GRADIENT !important;
    border-radius: $BORDER_RADIUS_SMALL;
    z-index: -1;
    @include box-shadow($primary-shadow);
  }
}

.disabled-outline-primary {
  @extend .btn-base-outline-primary;
  cursor: default;
  background: white !important;
  &:after {
    opacity: 0.5;
  }
}

.btn-full-width {
  flex: 1;
  width: 100%;
}
