@import "assets/styles/colors.scss";

.ht-container {
  margin-bottom: 16px;
  border-bottom: 1px solid $COLOR_BORDER;
  div {
    padding: 0 0 12px;
    width: fit-content;
    position: relative;
    span {
      font-weight: 500;
      font-size: 16px;
      text-transform: uppercase;
      color: $COLOR_TEXT;
    }
    &:before {
      content: "";
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: $COLOR_AEON_1;
    }
  }
}
