@import "assets/styles/colors.scss";
.pi-container {
  max-width: 120px;
  cursor: pointer;
  .pi-icon-outer {
    position: relative;
    overflow: hidden;
    border-radius: 24px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    .pi-subtext {
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: $COLOR_BLUE;
      color: white;
      font-size: 10px;
      width: 100%;
      > span {
        display: flex;
        justify-content: center;
        font-size: 11px;
        span {
          display: inline-block;
          padding-left: 3px;
        }
      }
    }
    .pi-subtext-blue {
      background-color: $COLOR_BLUE;
    }
    .pi-subtext-violet {
      background-color: $COLOR_VIOLET;
    }
    .small-text {
      > span {
        font-size: 8px;
      }
    }
  }
  .pi-icon-small {
    width: 80px;
    height: 80px;
  }
  .pi-icon-large {
    width: 100px;
    height: 100px;
  }
  .pi-name {
    margin: 12px 0px 0px;
    text-align: center;
    text-transform: uppercase;
    font-size: 13px;
  }
}
