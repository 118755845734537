@import 'assets/styles/colors.scss';
.checkbox-wrapper{
  display: flex;
  .checkbox {
    --background: #fff;
    --border: #00000033;
    --border-hover: #BBC1E1;
    --border-active: #c73183;
    --tick: #fff;
    position: relative;
    width: fit-content;
    input,
    svg {
      width: 24px;
      height: 24px;
      display: block;
    }
    input {
      -webkit-appearance: none;
      -moz-appearance: none;
      position: relative;
      outline: none;
      background: var(--background);
      border: none;
      margin: 0;
      padding: 0;
      align-self: center;
      cursor: pointer;
      border-radius: 4px;
      transition: box-shadow .3s;
      box-shadow: inset 0 0 0 var(--s, 1px) var(--b, var(--border));
      &:hover {
          --s: 2px;
          --b: var(--border-hover);
      }
      &:checked {
          --b: var(--border-active);
      }
    }
    svg {
      pointer-events: none;
      fill: none;
      stroke-width: 2px;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke: var(--stroke, var(--border-active));
      position: absolute;
      align-self: center;
      width: 24px;
      height: 24px;
      transform: scale(var(--scale, 1)) translateZ(0);
    }
    &.path {
      input {
        &:checked {
          --s: 2px;
          transition-delay: .4s;
          & + svg {
            --a: 16.1 86.12;
            --o: 102.22;
          }
        }
      }
      svg {
          stroke-dasharray: var(--a, 86.12);
          stroke-dashoffset: var(--o, 86.12);
          transition: stroke-dasharray .6s, stroke-dashoffset .6s;
      }
    }
    &.bounce {
      display: flex;
      --stroke: var(--tick);
      input {
        &:checked {
            --s: 15px;
            & + svg {
                animation: bounce .4s linear forwards .2s;
            }
        }
      }
      svg {
          --scale: 0;
      }
      span{
        font-weight: normal;
        margin-left: 12px;
        font-size: 15px;
        line-height: 24px;
        width: calc(100% - 24px);
      }
    }
  }
  img{
    width: 16px;
    height: 16px;
    margin-left: 5px;
    margin-bottom: .5rem;
    align-self: center;
  }
}

@keyframes bounce {
  50% {
      transform: scale(1.2);
  }
  75% {
      transform: scale(.9);
  }
  100% {
      transform: scale(1);
  }
}