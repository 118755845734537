@import "assets/styles/colors.scss";
.nf-container {
  color: $COLOR_TEXT;
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h3 {
    margin-bottom: 16px;
  }
}
