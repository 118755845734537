@import "assets/styles/colors.scss";
.history-title {
  text-transform: uppercase;
  font-weight: normal !important;
}

.history-content {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  padding-top: 0px;
}

.history-wrapper .modal-content {
  margin: 0 10px;

  .modal-header {
    border-bottom: none !important;
  }
  .modal-footer {
    border-top: none !important;
  }

  .modal-body {
    max-height: 70vh;
    overflow: auto;
  }
}

.history-person-item {
  padding: 16px 0px;
  border-top: thin solid;
  border-width: 1px;
  border-color: $COLOR_BORDER;
}
.history-listouter {
  max-height: 300px;
  overflow: auto;
  .no-data {
    text-align: center;
  }
}
