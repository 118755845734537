.outside {
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.55);
    z-index: 999;
    top: 0;
    left: 0;
    position: fixed;
    .close-menu{
        position: absolute;
        right: 20px;
        top: -30px;
        opacity: 0;
        visibility: hidden;
        transition: all 0.5s;
    }
}

.drawer.open ~ .outside .close-menu{
    opacity: 1;
    visibility: visible;
    top: 60px;
}
