@import 'assets/styles/colors.scss';

.background-header {
  background-image: url('../../assets/images/icons/background-header.svg');
  background-repeat: no-repeat;
  background-position: top;
  background-size: 100%;
  .box-sale {
    .title-sale-header {
      font-weight: 600;
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 24px;
      padding-top: 120px;
    }
    .des-sale {
      font-weight: 500;
      font-size: 16px;
      text-align: center;
      text-transform: uppercase;
      color: $COLOR_TEXT;
    }
  }
}
