.tm-notify {
  min-height: 36px;
  padding: 0 15px;
  align-items: center;
  margin: 0px 20px;
  position: relative;
  border-radius: 4px;
  animation: from-left 0.6s;
}

@keyframes from-left {
  0% {
    left: -100%;
    top: 0px;
  }
  100% {
    left: 0px;
    top: 0px;
  }
}

.alert-img {
  width: 20px;
  height: 20px;
  margin-right: 10.5px;
}

.tm-alert-success {
  background: #e8f5e9;
}

.tm-alert-unsuccess {
  background: #ffebee;
}
.tm-alert-warning {
  background: #fff3e0;
}
.tm-alert-notify {
  background: #e1f5fe;
}
