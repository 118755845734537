@import 'assets/styles/colors.scss';

.benefit-item {
  background-size: 35px auto;
  background-repeat: no-repeat;
  .title {
    font-weight: 500;
    color: $COLOR_TEXT;
    font-size: 16px;
    text-transform: uppercase;
    padding-top: 5px;
    padding-bottom: 12px;
    border-bottom: 1px solid $COLOR_BORDER;
  }
  .box-price {
    width: 25%;
    .price {
      font-size: 17px;
      font-weight: 500;
      margin-top: 12px;
      padding-bottom: 12px;
      &.active {
        color: $color-app-highlight;
      }
      span {
        font-weight: normal;
        font-size: 12px;
      }
    }
  }
  .box-title-and-price-benefit {
    margin: 12px 0px;
    border-bottom: 1px solid $COLOR_BORDER;
    &:last-child {
      border: none;
    }
  }
  .title-benefit {
    color: $color-sub-title;
    font-size: 15px;
  }
  .title-object {
    color: $COLOR_TEXT;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 8px;
    margin-top: 12px;
  }
  .box-benefit {
    margin-top: 20px;
  }
}
