@import "assets/styles/colors.scss";
.rs-item-wrapper {
  border-top: thin solid;
  border-width: 1px;
  border-color: $COLOR_BORDER;
  padding: 16px 0;
  justify-content: space-between;
  .user-info {
    font-size: 13px;
    line-height: 20px;
    .user-name {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-transform: uppercase;
      color: $color-app-highlight;
      margin-bottom: 8px;
    }
    .user-text {
      color: $TXT_BOLD_COLOR;
      .txt-data {
        font-weight: 700;
      }
    }
  }
  .action {
    display: flex;
    .icon {
      display: inline-block;
      width: 40px;
      height: 40px;
      background: $BTN_IC_BG_GRAY;
      margin-left: 12px;
      border-radius: 50%;
      position: relative;
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .rs-status {
    flex: 1;
    display: flex;
    white-space: nowrap;
    color: $TXT_BOLD_COLOR;
    background: $BTN_BG_GRAY;
    border-radius: 4px;
    padding: 4px 8px;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
  }
}
