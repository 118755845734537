.news-item-horizontal {
  .thumbnail {
    display: block;
    padding-top: 77%;
    position: relative;
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  .title {
    font-size: 15px;
    color: #022b39;
    line-height: 24px;
    margin-top: 12px;
    font-weight: 500;
  }
  .tag {
    margin-top: 8px;
    position: relative;
    padding-left: 24px;
    font-size: 12px;
    color: #ff9751;
    text-transform: uppercase;
    &:before {
      position: absolute;
      content: '';
      width: 20px;
      height: 20px;
      background-image: url('../../assets/images/icons/icon-tag.svg');
      background-size: 100%;
      background-repeat: no-repeat;
      left: 0;
      top: 0;
    }
  }
}
