.cfp-title {
  text-transform: uppercase;
  font-weight: normal !important;
}

.cfp-content {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  padding-top: 0px;
}

.cfp-wrapper .modal-content {
  margin: 0 10px;

  .modal-header {
    border-bottom: none !important;
  }
  .modal-footer {
    border-top: none !important;
  }

  .modal-body {
    max-height: 70vh;
    overflow: auto;
  }
}
