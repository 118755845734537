@import 'assets/styles/colors.scss';

.toggle-box {
  .switch-on,
  .switch-off {
    font-size: 0;
  }
  .switch {
    width: 68px;
    background-color: transparent !important;
    border-color: transparent !important;
    border: none;
    border-radius: 16px;
    box-shadow: none !important;
    .btn-primary,
    .btn-info {
      box-shadow: none !important;
    }
    .btn-primary {
      background: $color-app-highlight;
      &:active {
        border-color: transparent;
        background: $color-app-highlight;
      }
    }
    .btn-info {
      background: #cfd8dc;
      &:active {
        border-color: transparent;
        background: #cfd8dc;
      }
    }
    .switch-handle {
      height: 24px;
      width: 24px;
      border-radius: 50% !important;
      margin-right: 34px;
      margin-top: 3px;
    }
    &.off {
      .switch-handle {
        margin-right: 0px;
        margin-left: 34px;
      }
    }
  }
}
